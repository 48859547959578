@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Lato:100,300,400,500,500i,700,900&display=swap");

@font-face {
  font-family: "Rubik";
  src: url("../../fonts/RubikOne-Regular.ttf") format("truetype");
}

$darkColor: #3f3d56;
$darkerColor: #282737;
$lightPurple: #b769ff;
$icon-purple: #b25fff;
$font: "Rubik", sans-serif;
$lato: "Lato", sans-serif;
$defGrey: #bdbdbd;
$defPurp: #793db0;
$menuback: rgba(63, 61, 86, 0.5);
$defaultShadow: 0px 5px 7px rgba(0, 0, 0, 0.2);

.simplistic-page {
  height: 100%;

  .notification {
    position: fixed;
    top: 35%;
    height: 6em;
    width: 15em;
    background-color: $darkColor;
    box-shadow: $defaultShadow;
    display: flex;
    flex-direction: row;
    border-radius: 10px 0 0 10px;
    align-items: center;
    transition-property: right;
    transition-duration: 1s;

    .edge {
      background: linear-gradient(180deg, #793db0 0%, #b25fff 100%);
      width: 0.7em;
      height: 100%;
      border-radius: 10px 0 0 10px;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        font-family: $lato;
        color: $defGrey;
        font-size: 1em;
        text-transform: uppercase;
        margin: 0 1em;
        letter-spacing: 1px;
      }
    }
  }

  .simplistic-header {
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 4em;
    font-family: $font;
    font-size: 1em;
    top: 0;
    z-index: 777;

    &--item {
      // flex: 1;
      display: flex;

      &:first-child {
        align-self: flex-start;
      }

      &--two {
        justify-content: center;
        margin: auto;
      }

      &--three {
        justify-content: flex-end;
      }
    }

    ul {
      list-style: none;
      display: flex;
      border-radius: 10px;
      align-items: center;
      padding: 1.3em 2.7em;

      .active {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $icon-purple;

        button {
          color: $icon-purple;
        }

        &--underline-parent {
          justify-content: center;
          display: flex;
          align-items: center;
        }

        &--underline {
          position: absolute;
          border-bottom: 1px solid $icon-purple;
          width: 50%;
          padding: 0.2em;
        }
      }

      li {
        display: inline-block;
        margin: 0 1em;

        button {
          color: $defGrey;
          border: 0;
          background-color: transparent;
          font-family: $font;
          text-transform: uppercase;
          display: flex;
          font-size: 14px;
          text-align: center;
          outline: none !important;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .download-resume {
      text-decoration: none;
      position: relative;
      font-family: $font;
      text-transform: uppercase;

      span {
        font-family: $font;
        color: #ebebeb;

        &:first-child {
          position: absolute;
          left: -0.05em;
          color: $darkColor;
        }
      }
    }
  }

  .simplistic-body {
    .introduction {
      height: 100vh;
      display: flex;
      justify-content: center;

      &--new-image {
        height: 100%;
        width: 85%;
        max-width: 1000px;
        background: url("../../images/landing-image.png") no-repeat center
          center;
        background-size: contain;
        transition-property: left opacity;
        transition-duration: 2s;
        position: relative;
      }
    }

    .simplistic-tools {
      height: 100vh;
      background-color: $darkerColor;
      box-shadow: inset 0px 5px 7px rgba(0, 0, 0, 0.2);

      &--parent {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        &-grid {
          display: flex;
          flex-wrap: wrap;
          max-width: 770px;
          gap: 16px;
          justify-content: center;

          .tool {
            background-color: $darkColor;
            width: 224px;
            padding: 34px;
            text-align: center;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            @media (max-width: 782px) {
              width: 85%;
            }

            & > * {
              font-family: "Lato", sans-serif;
              letter-spacing: 0.08em;
            }

            &--img {
              width: 50px;
              height: auto;
            }

            &--title {
              font-size: 1.2em;
              color: $icon-purple;
            }

            &--description {
              font-size: 1em;
              color: $defGrey;
              line-height: 19px;
            }
          }
        }
      }

      &--buttons {
        display: flex;
        margin-top: 3em;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        a {
          position: relative;
          display: flex;
          align-items: center;
          font-family: "Lato", sans-serif;
          font-weight: bold;
          font-size: 1em;
          letter-spacing: 0.08em;
          text-align: center;
          color: $defGrey;
          background-color: $defPurp;
          border: 1px solid $defPurp;
          width: 12em;
          justify-content: center;
          align-items: center;
          height: 3.7em;
          text-transform: uppercase;
          border-radius: 6px;
          outline: none !important;
          margin: 1em;
          box-shadow: $defaultShadow;
          transition-property: background-color, border-color;
          transition-duration: 2s;

          &:hover {
            background-color: $lightPurple;
            border-color: $lightPurple;
          }

          img {
            position: absolute;
            right: 0.3em;
            margin-right: 0.3em;
            width: 0.5em;
            height: auto;
          }
        }
      }
    }

    .simplistic-message {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      &--title {
        display: none;
        color: $icon-purple;
        text-transform: uppercase;
        font-size: 2em;
        margin-bottom: 0.5em;
      }

      .simplistic-form {
        width: 50em;
        box-shadow: $defaultShadow;
        border-radius: 10px;

        &--header,
        &--footer {
          background: $darkColor;
          display: flex;
          align-items: center;
        }

        &--header {
          height: 2em;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          img {
            margin-left: 1em;
            height: auto;
          }
        }

        &--footer {
          height: 4em;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          &--form {
            padding: 0 1em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .disabled {
              opacity: 0.3;

              &:hover {
                cursor: not-allowed;
              }
            }

            button {
              outline: none !important;
              padding: 0 3.4em;
              border: 0;
              background: $defPurp;
              color: $defGrey;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              font-family: "Lato", sans-serif;
              font-weight: bold;
              font-size: 12px;

              img {
                position: absolute;
                right: 0.5em;
              }
            }

            input {
              outline: none !important;
              background: $darkerColor;
              border-radius: 6px;
              border: 0;
              font-family: "Lato", sans-serif;
              padding: 1em 2em;
              box-shadow: $defaultShadow;
              color: $defGrey;
              letter-spacing: 0.05em;

              &:first-child {
                margin-right: 1em;
              }
            }
          }
        }

        &--text-area {
          background: $darkerColor;
          box-shadow: inset 0px 5px 7px rgba(0, 0, 0, 0.2);

          textarea {
            outline: none !important;
            width: 100%;
            height: 25em;
            background: transparent;
            border: 0;
            padding: 2em;
            font-family: "Lato", sans-serif;
            font-size: 1em;
            color: $defGrey;
            outline: none !important;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }

  footer {
    text-align: center;
    color: $defGrey;
    padding: 1em;
    position: absolute;
    bottom: 0;

    p {
      font-family: "Lato", sans-serif;
      font-size: 12px;
    }
  }
}

@media (max-width: 1018px) {
  .simplistic-page {
    .simplistic-body {
      .introduction {
        align-items: center;

        img {
          width: 90%;
        }

        &--new-image {
          background: url("../../images/description.png") no-repeat center
            center;
          background-size: contain;
        }
      }

      .simplistic-tools {
        &--image-parent {
          width: 90%;
        }

        &--grid-parent {
          background: red;

          .tool {
            background-color: $darkColor;
            width: 20em;
            height: 20em;

            .icon {
              width: 40px;
            }
          }
        }
      }

      .simplistic-message {
        form {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .simplistic-form {
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 782px) {
  .simplistic-page {
    .simplistic-header {
      display: none;
      justify-content: center;

      div {
        &:last-child {
        }
      }
    }

    .simplistic-body {
      .introduction {
        &--image-main {
          height: auto;
          width: 90%;
          max-width: 800px;
        }
      }

      .simplistic-tools {
        background: none;
        height: auto;
        box-shadow: none;
        border-bottom: 2px solid $darkerColor;

        &--buttons {
          margin-bottom: 3em;
          width: 100%;

          a {
            width: 80%;

            img {
              position: absolute;
              right: 1em;
              width: 1em;
            }
          }
        }

        &--parent {
          img {
            // width: 95%;
          }
        }

        &--image-parent {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $darkerColor;
          box-shadow: inset 0px 5px 7px rgba(0, 0, 0, 0.2);
          padding: 0.1em 0;
        }

        &--image-main {
          display: none;
        }
      }

      .simplistic-message {
        height: auto;
        padding: 3em 0 0;
        background: $darkerColor;

        &--title {
          display: block;
        }

        form {
        }

        .simplistic-form {
          width: 95%;

          &--header {
            border-radius: 0;
            height: 3em;
          }

          &--footer {
            height: auto;
            border-radius: 0;

            &--form {
              flex-direction: column;

              .input-parent {
                flex-direction: column;
              }

              input,
              button {
                border-radius: 0;
                font-size: 1em;
                height: 4em;
                width: 100%;
                margin: 0.5em 0;
              }

              button {
                text-transform: uppercase;

                img {
                  right: 2em;
                }
              }
            }
          }
        }
      }
    }

    footer {
      padding: 1em;
      position: static;
    }
  }
}
