@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Rubik+Mono+One&display=swap');

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/RubikOne-Regular.ttf') format('truetype')
}

$background: #3F3D56;
$font: 'Rubik', sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $font;
}

a {
  text-decoration: none;
}

body {
  font-size: 14px;
  background: $background;
  background-image: url('./images/back.svg');
  background-size: cover;
  background-repeat: no-repeat;
  // position: relative;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent !important;
    // display: none!important;
  }
}
